import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';


type DatabaseProps = {}



const DatabaseActives:FunctionComponent<DatabaseProps> = (props) => {

  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState(Array());

  const handleGetTasks = async () => {
    setLoading(true);
    try {
      const { success, data, message } = await ObexRequestsHandler.get('/tasks');
      if (!success) throw { message };   
      setTasks(data);
      console.log(data);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  useEffect(()  => {
    //handleGetTasks();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="database">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Database Actives</h1>
            </Col>
          </Row>
          <Row className="mb-3 border-bottom">
            <Col>Name</Col>
            <Col>Project</Col>
            <Col>other data</Col>
          </Row>
          {/* {tasks.map(task => { 
            return ( */}
              <Row className="mb-3">
                <Col className="">
                  <span></span>
                </Col>
                <Col className="">
                  <span></span>
                </Col>
                <Col className="">
                  <span></span>
                </Col>
              </Row>
            {/* )}
          )} */}
        </Col>
      </Row>
    </DashLayout>
  )
}

export default DatabaseActives;