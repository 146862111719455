import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory,useParams } from 'react-router-dom';

type ClientsProps = {}


const ClientBilling:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  const { id } = useParams();
  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState(String());
  const [email, setEmail] = useState(String());
  const [isValidated, setIsValidated ] = useState(false);
  const [stripeCustomerId, setSripeCustomerId ] = useState(String());
  const [stripeSignupCard, setStripeSignupCard ] = useState(String());
  const [stripeDefaultCard, setStripeDefaultCard ] = useState(String());
  const [isAdmin, setIsAdmin ] = useState(false);


  const getClientsDetail = async () => {
    setLoading(true);
    try {
      const { success, data, message } = await ObexRequestsHandler.get(`/users/${id}`);
      if (!success) throw { message };
      const { username, email,
              validated, stripe_customer_id, 
              stripe_signup_card, stripe_default_card, 
              admin } = data;
      setUsername(username);
      setEmail(email);
      setIsValidated(validated);
      setSripeCustomerId(stripe_customer_id);
      setStripeSignupCard(stripe_signup_card);
      setStripeDefaultCard(stripe_default_card);
      setIsAdmin(admin);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  const handleClientDetail = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/clients/${client.id}`);
  }

  useEffect(()  => {
    getClientsDetail();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="clients">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
              <h1 className="mb-3">Clients Acccount Setting</h1>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <Row>
                    <Col className="mx-auto">
                      <Form className="obex-form">
                        <Form.Row className="mb-3">
                          <Col md="6">
                            <Form.Label className="obex-form-label">
                              Username
                            </Form.Label>  
                            <Form.Control
                              required
                              readOnly
                              type="nubmer"
                              placeholder="Username"
                              value={username}
                            />
                          </Col>
                          <Col md="6">
                            <Form.Label className="obex-form-label">
                              Email
                            </Form.Label>  
                            <Form.Control
                              required
                              readOnly
                              type="nubmer"
                              placeholder="Email"
                              value={email}
                            />
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Col md="6">
                            <Form.Label className="obex-form-label">
                              Stripe customer id
                            </Form.Label>  
                            <Form.Control
                              required
                              readOnly
                              type="nubmer"
                              placeholder="Stripe customer id"
                              value={stripeCustomerId}
                            />
                          </Col>
                          <Col md="6">
                            <Form.Label className="obex-form-label">
                              Stripe default card
                            </Form.Label>  
                            <Form.Control
                              required
                              readOnly
                              type="nubmer"
                              placeholder="Stripe default card"
                              value={stripeDefaultCard}
                            />
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="6" className="obex-form-label">
                            Validated
                          </Form.Label>
                          <Col md="6" >
                            <Form.Check
                              readOnly
                              checked={isValidated}
                              type="switch"
                              size={5}
                              label=''
                              id="cipher-switch"
                              className="obex-switch text-right"
                            />
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="6" className="obex-form-label">
                            Admin
                          </Form.Label>
                          <Col md="6" >
                            <Form.Check
                              readOnly
                              checked={isAdmin}
                              type="switch"
                              size={5}
                              label=''
                              id="cipher-switch"
                              className="obex-switch text-right"
                            />
                          </Col>
                        </Form.Row>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </DashLayout>
  )
}

export default ClientBilling;