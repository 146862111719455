import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../../basicComponents/buttonSubmit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import ObexAlert from '../../basicComponents/ObexAlerts';
import { validatePass } from '../../../services/validationService';
import SubsSuccessContent from './subscriptionSuccess';


type CreateSubsContentProps = {
  handleProjectsNumbers: any,
  handleMonthRateLimit: any,
  handleNameSubs: any,
  handleMail: any,
  handleProject: any,
  handleStaging: any,
  handleCipher: any,
  error: any,
  success: any,
  loading: any,
  submitDisabled: any,
  handleSubmit: any,
  cipher: boolean,
  project: boolean,
  staging: boolean
}


const CreateSubsContent:FunctionComponent<CreateSubsContentProps> = (props) => {

  const { handleProjectsNumbers, handleMonthRateLimit, handleNameSubs, 
          handleMail, handleProject, handleStaging, handleCipher, error, 
          success, loading, submitDisabled, handleSubmit, cipher, project, staging} = props;

  return(
    <Row>
      <Col md="9" className="mx-auto">
        <Row>
          <Col>
          <h1 className="mb-3">Account setting</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="product-detail-card mb-3">
              <Card.Body className="p-3">
                <Row>
                  <Col className="mx-auto">
                    <Form className="obex-form">
                      <Form.Row className="mb-3">
                        <Form.Label column sm="12" className="obex-form-label">
                          Subscription Name
                        </Form.Label>
                        <Col md="12">
                          <Form.Control required type="nubmer" placeholder="Subscription Name" onChange={handleNameSubs}/>
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Col md="6">
                          <Form.Label className="obex-form-label">
                            Projects Number
                          </Form.Label>  
                          <Form.Control required type="nubmer" placeholder="Projects Numbers" onChange={handleProjectsNumbers}/>
                        </Col>
                        <Col md="6">
                          <Form.Label className="obex-form-label">
                            Month Rate Limit
                          </Form.Label>  
                          <Form.Control required type="nubmer" placeholder="Month Rate Limit" onChange={handleMonthRateLimit}/>
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Form.Label column sm="12" className="obex-form-label">
                          Email
                        </Form.Label>
                        <Col md="12">
                          <Form.Control required type="email" placeholder="Email" onChange={handleMail}/>
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-1">
                        <Form.Label column sm="6" className="obex-form-label">
                          Create project
                        </Form.Label>
                        <Col md="6">
                          <Form.Check 
                            checked={project}
                            type="switch"
                            size={5}
                            label=''
                            id="project-switch"
                            onChange={handleProject}
                            className="obex-switch text-right"
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-1">
                        <Form.Label column sm="6" className="obex-form-label">
                          Create staging project
                        </Form.Label>
                        <Col md="6">
                          <Form.Check 
                            checked={staging}
                            type="switch"
                            size={5}
                            label=''
                            id="staging-switch"
                            onChange={handleStaging}
                            className="obex-switch text-right"
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-3">
                        <Form.Label column sm="6" className="obex-form-label">
                          Enable cipher capability
                        </Form.Label>
                        <Col md="6" >
                          <Form.Check 
                            checked={cipher}
                            type="switch"
                            size={5}
                            label=''
                            id="cipher-switch"
                            onChange={handleCipher}
                            className="obex-switch text-right"
                          />
                        </Col>
                      </Form.Row>
                      <ButtonSubmit 
                        submitDisabled={submitDisabled}
                        success={success}
                        loading={loading}
                        error={error}
                        align="right"
                        textButton="Create"
                        handleSubmit={handleSubmit}
                      />
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}



type AccountProps = {
}

const CreateSubcription:FunctionComponent<AccountProps> = (props) => {

  const [ projectsNumbers, setProjectsNumbers ] = useState('');
  const [ monthRateLimit, setMonthRateLimit ] = useState('');
  const [ nameSubs, setNameSubs ] = useState('');
  const [ project, setProject] = useState(false);
  const [ staging, setStaging ] = useState(false);
  const [ cipher, setCipher ] = useState(false);
  const [ mail, setMail ] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ submitDisabled, setSubmitDisabled ] = useState(true);
  const [ createdSuccess, setCreatedSuccess ] = useState(false);
  const [ userInfo, setUserInfo ] = useState({});
  
  const handleProjectsNumbers = (e) => {setProjectsNumbers(e.target.value)}
  const handleMonthRateLimit = (e) => {setMonthRateLimit(e.target.value)}
  const handleNameSubs = (e) => {setNameSubs(e.target.value)}
  const handleMail = (e) => {setMail(e.target.value)}
  const handleProject = (e) => {setProject(!project)};
  const handleStaging = (e) => {setStaging(!staging)};
  const handleCipher = (e) => {setCipher(!cipher)};

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setError('');
    setLoading(true);
    const subscription = await handleSubs();
    console.log('subscription ', subscription)
    const { id } = subscription;
    setUserInfo(await handleCustomUser(id));
    setCreatedSuccess(true);
    setLoading(false);
  };

  const handleSubs = async () => {
    try {
      const payload = {
        projects_numbers: projectsNumbers,
        month_rate_limit: monthRateLimit,
        name: nameSubs
      }
      const { success, data, message } = await ObexRequestsHandler.post('/subscriptions', payload);
      if (!success) throw { message };   
      return data;
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    }

  }

  const handleCustomUser = async (subscription) => {
    try {
      const payload = {
        email: mail,
        subscription: subscription,
        project: project,
        staging: staging,
        cipher: cipher
      }
      const { success, data, message } = await ObexRequestsHandler.post('/create_custom_user', payload);
      if (!success) throw { message };   
      return data;
    } catch (error) {
      setError(error.message || error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (projectsNumbers && monthRateLimit && nameSubs && mail){
      setSubmitDisabled(false);
    }else{
      setSubmitDisabled(true);
    }
  },[ projectsNumbers, monthRateLimit, nameSubs, mail ])

  return (
    <DashLayout sider={DashRouters} active="subscription">
      {createdSuccess ? 
      <SubsSuccessContent 
        userInfo={userInfo}
      />:
      <CreateSubsContent 
        handleProjectsNumbers={handleProjectsNumbers}
        handleMonthRateLimit={handleMonthRateLimit}
        handleNameSubs={handleNameSubs}
        handleMail={handleMail}
        handleProject={handleProject}
        handleStaging={handleStaging}
        handleCipher={handleCipher}
        error={error}
        success={success}
        loading={loading}
        submitDisabled={submitDisabled}
        handleSubmit={handleSubmit}
        cipher={cipher}
        project={project}
        staging={staging}
      />}
    </DashLayout>
  );
}

export default CreateSubcription;