import RequestHandler from '../handlers/RequestHandler';
import ObexRequestHandler from '../handlers/ObexRequestsHandler';
import ErrorHandler from '../handlers/ErrorHandler';
import SessionStorageHandler from '../handlers/SessionStorageHandler';
import Cookies from 'universal-cookie/cjs/Cookies';


class SessionService {
  private static secondsTimeout;
  private static checkInactivityInterval;
  private static sinceGetTokenInterval;
  private static secondsSinceGetToken  = 0;
  private static inactivitySecondCounter = 0;
  private static dispatchedInactivity = false;

 
  // Evento para cuando se expira la sesión
  private static sessionExpiredEvent = document.createEvent('Event');
  private static inactivityEvent = document.createEvent('Event');

  private static restartInactivitySecondCounter = (e) => {
    if (!SessionService.dispatchedInactivity) SessionService.inactivitySecondCounter = 0;
  }

  public static async login(email: string, password: string):Promise<any> {
    try {
      SessionService.dispatchedInactivity = false;
      const payload:object = {
        email,
        password
      }
      const result =  await ObexRequestHandler.post('/signin', payload);
      const { success, data, message } = result;
      if (!success) throw { message };
      const { secondsTokenExpiration } =  data; // Data es la info del login, el token, si es admin etc.
      SessionService.secondsTimeout = secondsTokenExpiration;
      SessionService.sinceGetTokenInterval= setInterval(() => {
        SessionService.secondsSinceGetToken++;
        SessionService.inactivitySecondCounter++;
      }, 1000);
      SessionService.setExpirationCountdown(data);
      document.addEventListener('click', SessionService.restartInactivitySecondCounter, false);
      document.addEventListener('mouseover', SessionService.restartInactivitySecondCounter, false);
      document.addEventListener('keypress', SessionService.restartInactivitySecondCounter, false);
      return data;
    } catch (error) {
      throw error;
    }
  }

  public static dispatchSessionExpiredEvent():void {
    SessionService.sessionExpiredEvent.initEvent('sessionExpiredEvent', true, true);
    document.dispatchEvent(SessionService.sessionExpiredEvent);
  }

  public static dispatchInactivityEvent():void {
    SessionService.inactivityEvent.initEvent('inactivityEvent', true, true);
    document.dispatchEvent(SessionService.inactivityEvent);
  }

  public static checkInactivity(expiration):void {
    const allowInactivitySeonds = 60;
    if (SessionService.secondsSinceGetToken >= expiration) {
      if (SessionService.inactivitySecondCounter < allowInactivitySeonds) {
        SessionService.renewSession();
      } else {
        if (!SessionService.dispatchedInactivity) {
          SessionService.dispatchInactivityEvent();
          SessionService.dispatchedInactivity = true;
        }
      }
    }
  }

  private static getExpirationDate():Date {
    const sessionExpiration = SessionService.secondsTimeout;
    const date = new Date();
    date.setSeconds(date.getSeconds() + sessionExpiration)
    return date;
  }

  public static async renewSession():Promise<void> {
    try {
      const result = await ObexRequestHandler.get('/session/renew');
      const { success, data, message } = result; // data es el token
      if (!success) throw { message };
      const cookies = new Cookies();
      const userInfo = cookies.get('101Obex');
      userInfo.token = data; // Actualizamos el token
      const expirationDate = SessionService.getExpirationDate();
      cookies.set('101Obex', JSON.stringify(userInfo), { path: '/', expires: expirationDate });
      SessionService.secondsSinceGetToken = 0;
      SessionService.dispatchedInactivity = false;
    } catch (error) {
      throw error;
    }
  }

  private static setExpirationCountdown(userInfo):void {
    const expirationDate = SessionService.getExpirationDate();
    const cookies = new Cookies();
    cookies.set('101Obex', JSON.stringify(userInfo), { path: '/', expires: expirationDate });
    const expiresIn = (SessionService.secondsTimeout  - 30); // Damos 30 segundos de margen
    SessionService.checkInactivityInterval = setInterval(() => SessionService.checkInactivity(expiresIn), 1000);
  }

  public static async logout():Promise<void> {
    try {
      const cookies = new Cookies();
      cookies.remove('101Obex');
      clearInterval(SessionService.checkInactivityInterval);
      clearInterval(SessionService.sinceGetTokenInterval);
      SessionService.secondsSinceGetToken  = 0;
      SessionService.inactivitySecondCounter = 0;
      document.removeEventListener('click', SessionService.restartInactivitySecondCounter, false);
      document.removeEventListener('mouseover', SessionService.restartInactivitySecondCounter, false);
      document.removeEventListener('keypress', SessionService.restartInactivitySecondCounter, false);
    } catch (error) {
      throw error;
    }
  }

  public static isAdmin():boolean {
    const cookies = new Cookies();
    const sessionCookie = cookies.get('101Obex');
    if (sessionCookie && sessionCookie.admin){
      return true;
    }
    return false;
  } 
}

export default SessionService;