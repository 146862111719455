import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory,useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck  } from '@fortawesome/free-solid-svg-icons';

type ClientsProps = {}


const ClientAccount:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  const { id } = useParams();
  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [cities, setCities] = useState([]);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [cityId, setCity] = useState('');
  const [locality, setLocality] = useState('');
  const [phone, setPhone] = useState('');
  const [timeZoneId, setTimeZone] = useState('');
  const [statementDescriptor, setStateDescriptor] = useState('');
  const [verifyEnabled, setVerify] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [poneVerified, setPhoneVerified] = useState(false);

  const getAccountSetting = async () => {
    setLoading(true);
    try {
      const { success, data, message } = await ObexRequestsHandler.get(`/account_settings/${id}`);
      if (!success) throw { message };
      const {
        account_name, address_line_1,
        address_line_2, city, country,
        country_code, latitude, locality,
        lonitude, obex_city_id, obex_timezone,
        postal_code, statement_descriptor, timezone
      } = data;
      setAccountName(account_name);
      setAddressLine1(address_line_1);
      setAddressLine2(address_line_2);
      setPostalCode(postal_code);
      setCity(city);
      setLocality(locality);
      setTimeZone(timezone);
      setStateDescriptor(statement_descriptor);
    } catch (error) {
      setError(error.message || error)
    };
    setLoading(false);
  };

  const getCountries = async () => {
    try {
      const result = (await ObexRequestsHandler.get('/countries')).data;
      setCountries(result);
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }
  }

  const getTimezones = async () => {
    try {
      const result = (await ObexRequestsHandler.get('/timezones')).data;
      setTimezones(result);
    } catch (error) {
      console.error('ERROR FETCHING TIMEZONES ', error);
    }
  }

  const handleClientDetail = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/clients/${client.id}`);
  }

  const countriesList = countries.map(c => <option value={c.country_code} selected={locality == c.country_code }>{c.country}</option>);
  const citiesList = cities.map(c => <option value={c.id} >{c.city}</option>);
  const timezonesList = timezones.map(t => <option value={t.id} >{t.name}</option>);

  useEffect(()  => {
    getAccountSetting();
    getCountries();
    getTimezones();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="clients">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
              <h1 className="mb-3">Clients Acccount Setting</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <Row>
                    <Col className="mx-auto">
                      <Form className="obex-form">
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Account Name (email)
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required type="email" value={accountName} placeholder="Account Name (email)"/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Business Address
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required type="text" value={addressLine1} placeholder="Addresss line"/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Col md="9">
                            <Form.Control type="text" value={addressLine2} placeholder="Addres line 2"/>
                          </Col>
                          <Col md="3">
                            <Form.Control required type="number" value={postalCode} placeholder="Postal code"/>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Col md="8">
                            <Form.Control required as="select" placeholder='City' custom>
                              {cities.length > 0 
                                ? citiesList
                                : <option value="" selected disabled hidden>First choose a country</option>
                              }
                            </Form.Control>  
                          </Col>
                          <Col md="4">
                            <Form.Control required as="select" placeholder='Country' custom>
                              <option value="" selected disabled hidden>Choose country</option>
                              {countriesList}
                            </Form.Control>  
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Phone verification
                          </Form.Label>
                          <Col md={12}>
                          	<Row>
                              <Col md="8" className="pr-0">
                              	  <FormControl
                                    type="number"
                              	    placeholder="Phone"
                              	    aria-describedby="phone-verification"
                              	  />
                              	</Col>
                              	<Col md={4}>
                              	  <Button className="btn-block obex-btn btn-green btn-verify">
                              	    Verify
                              	  </Button>
                              	</Col>
                            </Row>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Time zone
                          </Form.Label>
                          <Col md="12">
                            <Form.Control required as="select" custom>
                                <option value="" selected disabled hidden>Choose a timezone</option>
                                {timezonesList}
                              </Form.Control>
                          </Col>
                        </Form.Row>
                        <Form.Row className="mb-3">
                          <Form.Label column sm="12" className="obex-form-label">
                            Statement descriptor
                          </Form.Label>
                          <Col md="12">
                            <Form.Control 
                              required 
                              type="text" 
                              placeholder="Statement descriptor" 
                            />
                          </Col>
                        </Form.Row>
                        <Row className="mt-3">
                          <Col md="4" className="ml-auto">
                          <Button type="submit" className="btn-block obex-btn btn-green">
                            Update {success && <FontAwesomeIcon icon={faCheck}/>}
                            {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                          </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashLayout>
  )
}

export default ClientAccount;