import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory,useParams } from 'react-router-dom';

type ClientsProps = {}


const ClientCommunications:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  const { id } = useParams();
  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [extaProjects, setExtaProjects] = useState(Array());
  const [extraRequests, setExtaRequests] = useState(Array());


  const getPurchasedServices = async () => {
    setLoading(true);
    try {
      const { success, data, message } = await ObexRequestsHandler.get(`/purchased_services/${id}`);
      console.log("PURCHASED SERVICES: ", data);
      if (!success) throw { message };
      const { extraProjectsPlans, extraRequestsPlans } = data;
      setExtaProjects(extraProjectsPlans);
      setExtaRequests(extraRequestsPlans);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  const handleClientDetail = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/clients/${client.id}`);
  }

  useEffect(()  => {
    getPurchasedServices();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="clients">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
              <h1 className="mb-3">Client Communication Usage</h1>
            </Col>
          </Row>

          <Row>
            <Col>
                {extaProjects.map(addonP => {
                  return (
                    <Card className="product-detail-card mb-3">
                      <Card.Body className="p-3">
                      <Row>
                        <Col md="6"><span>Title</span></Col>
                        <Col md="6"><span>{addonP.title}</span></Col>
                        <Col md="6"><span>Expire</span></Col>
                        <Col md="6"><span>{addonP.expires}</span></Col>
                        <Col md="6"><span>Status</span></Col>
                        <Col md="6"><span>{addonP.active ? "True" : "False"}</span></Col>
                        <Col md="6"><span>Renovate</span></Col>
                        <Col md="6"><span>{addonP.renovate ? "True" : "False"}</span></Col>
                      </Row>
                      </Card.Body>
                    </Card>
                  )
                })}
            </Col> 
          </Row>
          <Row>
            <Col>
              <h3 className="mb-3">Extra Requests</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              {extraRequests.map(addonR => {
                return (
                  <Card className="product-detail-card mb-3">
                    <Card.Body className="p-3">
                    <Row>
                      <Col md="6"><span>Title</span></Col>
                      <Col md="6"><span>{addonR.title}</span></Col>
                      <Col md="6"><span>Expire</span></Col>
                      <Col md="6"><span>{addonR.expires}</span></Col>
                      <Col md="6"><span>Status</span></Col>
                      <Col md="6"><span>{addonR.active ? "True" : "False"}</span></Col>
                      <Col md="6"><span>Renovate</span></Col>
                      <Col md="6"><span>{addonR.renovate ? "True" : "False"}</span></Col>
                    </Row>
                    </Card.Body>
                  </Card>
                )
              })}
            </Col>
          </Row>  
        </Col>
      </Row>
    </DashLayout>
  )
}

export default ClientCommunications;