import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory,useParams } from 'react-router-dom';

type ClientsProps = {}


const ClientStorage:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  const { id } = useParams();
  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [storageUsed, setStorageUsed] = useState('-');
  const [storageLimit, setStorageLimit] = useState('-');


  const getDatabaseUsage = async () => {
    setLoading(true);
    try {
      const { success, data, message } = await ObexRequestsHandler.get(`/database_usage/`, {id : id});
      if (!success) throw { message };
      console.log("Database Usage", data);
      if (!success) throw { message };
      const storageUsed = (data.used.total / 1e+6).toFixed(2).toString();
      const storageLimit = (data.allowed.total.db_size_total  / 1e+6).toFixed(2).toString();
      setStorageUsed(storageUsed);
      setStorageLimit(storageLimit);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  const handleClientDetail = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/clients/${client.id}`);
  }

  useEffect(()  => {
    getDatabaseUsage();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="clients">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
              <h1 className="mb-3">Client Contracted Storage </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <Row>
                    <Col className="mx-auto">
                      <Form className="obex-form">
                        <Form.Row className="mb-3">
                          <Col md="6">
                            <Form.Label className="obex-form-label">
                              Storage Used
                            </Form.Label>  
                            <Form.Control
                              required
                              readOnly
                              type="text"
                              placeholder="Storage Used"
                              value={`${storageUsed} MB`}
                            />
                          </Col>
                          <Col md="6">
                            <Form.Label className="obex-form-label">
                              Storage limit
                            </Form.Label>  
                            <Form.Control
                              required
                              readOnly
                              type="text"
                              placeholder="Storage limit"
                              value={`${storageLimit} MB`}
                            />
                          </Col>
                        </Form.Row>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashLayout>
  )
}

export default ClientStorage;