import {  RouteProps, Route, Redirect } from 'react-router-dom';
import LoginForm from '../components/loginForm/LoginForm';
import CountriesButton from '../components/functionalComponents/GetCountriesButton';
import React from 'react';
import SessionService from '../services/SessionsService';
import CreateSubcriptionContent from '../components/DashboardComponents/AdminComponents/createSubcription';
import PluginsContent from '../components/pluginsPage/pluginsContent';
import PluginDetail from '../components/pluginsPage/pluginDetail';
import CronsContent from '../components/DashboardComponents/CronsComponents/cronsContent';
import ClientsContent from '../components/ClientsComponents/clientContent';
import ClientStatistics from '../components/ClientsComponents/clientStatistics';
import DatabaseContent from '../components/DatabaseComponents/databaseContent';
import DatabaseActives from '../components/DatabaseComponents/databaseActives';
import DatabaseRemoves from '../components/DatabaseComponents/databaseRemoves';
import AddonsContent from '../components/AddonsComponents/addonsAdd';
import AddonsAdd from '../components/AddonsComponents/addonsAdd';
import AddonsDelete from '../components/AddonsComponents/addonsDelete';
import AddonsConfiguration from '../components/AddonsComponents/addonsConfig';
import SupportConfiguration from '../components/SupportComponents/supportConfig';
import SupportAdd from '../components/SupportComponents/supportAdd';
import SupportDelete from '../components/SupportComponents/supportDelete';
import WebhooktList from '../components/WebhookComponents/webhookList';
import WebhookModify from '../components/WebhookComponents/webhookModify';
import WebhookDelete from '../components/WebhookComponents/webhookDelete';
import WebhooktAdd from '../components/WebhookComponents/webhookAdd';
import DomianList from '../components/DomainComponents/domainList';
import DomainAdd from '../components/DomainComponents/domainAdd';
import DomainDelete from '../components/DomainComponents/domainDelete';
import ControlAccountList from '../components/ControlAccountComponents/ControlAccountList';
import ControlAccountAnnotate from '../components/ControlAccountComponents/controlAccountAnnotate';
import ServiceProjectBenefit from '../components/ServiceProjectsComponents/serviceProjectsBenefit';
import ServiceProjectsRepresentatives from '../components/ServiceProjectsComponents/serviceProjectsRepresentatives';
import Red101ObexBenefit from '../components/Red101obexComponents/red101obexBenefit';
import Red101ObexRepresentatives from '../components/Red101obexComponents/red101obexRepresentatives';
import FxExchangeConfiguration from '../components/fxExchangeComponents/FxExchangeConfiguration';
import FxExchangeBenefit from '../components/fxExchangeComponents/FxExchangeBenefit';
import ClientLicenseData from '../components/ClientsComponents/clientLicenseData';
import ClientBilling from '../components/ClientsComponents/clientBilling';
import ClientAccount from '../components/ClientsComponents/clientAccount';
import ClientAddons from '../components/ClientsComponents/clientAddons';
import ClientStorage from '../components/ClientsComponents/clientStorage';
import ClientProjects from '../components/ClientsComponents/clientProjects';
import ClientProjectPlugins from '../components/ClientsComponents/clientProjectsPlugins';
import ClientProjectUsers from '../components/ClientsComponents/clientProjectsUsers';
import ClientProjectUsersBlocked from '../components/ClientsComponents/clientProjectsUsersBlocked';
import PluginAdd from '../components/pluginsPage/pluginAdd';
import ClientCommunications from '../components/ClientsComponents/clientCommunications';


const Private = () => {return <h1>PRIVADO</h1>};
const Public = () => {return <h1>PUBLICO</h1>};
const isAdmin = ():boolean => SessionService.isAdmin();

/**
 * Este bloque crea un componente que usaremos para rutas privadas
 * es decir, todas aquellas que se necesite estar logueado previamente
 * el funcionamiente lo que hace es comprobar si está loguead manda a la ruta, 
 * en caso de que no redirecciona al login.
 */
export const PrivateRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
          1 > 2 ? ( // Comprobacion chorra(TOTAL NADIE USA ESTO)
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};


export const RootRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
          isAdmin() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

// Rutas a las que todo el mundo tiene acceso
export const publicRoutes:Array<RouteProps> = [
  {
    path: '/',
    component: () => <LoginForm/>,
    exact: true
  },
  {
    path: '/login',
    component: () => <LoginForm/>,
    exact: true
  }
]

//{(props) => <Dashboard {...props} isAuthed={true} />}

// Rutas privadas, necesitas estar logueado
export const privateRoutes:Array<RouteProps> = [
  {
    path: '/private',
    component: Private,
    exact: true
  },
]



// Rutas de administrador, debe de estar logueado y ser admin TODO ver esto
export const adminRoutes:Array<RouteProps> = [
  {
    path: '/crons',
    component: () => <CronsContent/>
  },
  {
    path: '/subscriptions',
    component: () => <CreateSubcriptionContent/>
  },
  {
    path: '/subscriptions/create',
    component: () => <CreateSubcriptionContent/>
  },
  {
    path: '/plugins/add',
    component: () => <PluginAdd/>,
    exact: true
  },
  {
    path: '/plugins/:namePlugin',
    component: () => <PluginDetail/>,
    exact: true
  },
  {
    path: '/plugins',
    component: () => <PluginsContent/>,
  },
  {
    path: '/addons/configuration',
    component: () => <AddonsConfiguration/>
  },
  {
    path: '/addons/add',
    component: () => <AddonsAdd/>
  },
  {
    path: '/addons/delete',
    component: () => <AddonsDelete/>
  },
  {
    path: '/addons',
    component: () => <AddonsConfiguration/>
  },
  {
    path: '/support/add',
    component: () => <SupportAdd/>
  },
  {
    path: '/support/delete',
    component: () => <SupportDelete/>
  },
  {
    path: '/support',
    component: () => <SupportConfiguration/>
  },
  {
    path: '/webhook/list',
    component: () => <WebhooktList/>
  },
  {
    path: '/webhook/add',
    component: () => <WebhooktAdd/>
  },
  {
    path: '/webhook/delete',
    component: () => <WebhookDelete/>
  },
  {
    path: '/webhook/modify',
    component: () => <WebhookModify/>
  },
  {
    path: '/webhook',
    component: () => <WebhooktList/>
  },
  {
    path: '/domains/delete',
    component: () => <DomainDelete/>
  },
  {
    path: '/domains/add',
    component: () => <DomainAdd/>
  },
  {
    path: '/domains/list',
    component: () => <DomianList/>
  },
  {
    path: '/domains',
    component: () => <DomianList/>
  },
  {
    path: '/admin',
    component: () => <CreateSubcriptionContent/>
  },
  {
    path: '/clients/list',
    component: () => <ClientsContent/>
  },
  {
    path: '/clients/:id/storage',
    component: () => <ClientStorage/>
  },
  {
    path: '/clients/:id/addons',
    component: () => <ClientAddons/>
  },
  {
    path: '/clients/:id/communications',
    component: () => <ClientCommunications/>
  },
  {
    path: '/clients/:id/account',
    component: () => <ClientAccount/>
  },
  {
    path: '/clients/:id/billing',
    component: () => <ClientBilling/>
  },
  {
    path: '/clients/:id/billing/resume',
    component: () => <ClientBilling/>
  },
  {
    path: '/clients/:id/billing/bank-account-data',
    component: () => <ClientBilling/>
  },
  {
    path: '/clients/:id/billing/withdrawal-funds',
    component: () => <ClientBilling/>
  },
  {
    path: '/clients/:id/billing',
    component: () => <ClientBilling/>
  },
  {
    path: '/clients/:id/projects',
    component: () => <ClientProjects/>
  },
  {
    path: '/clients/:id/projects/:project/plugins',
    component: () => <ClientProjectPlugins/>
  },
  {
    path: '/clients/:id/projects/:project/users',
    component: () => <ClientProjectUsers/>
  },
  {
    path: '/clients/:id/projects/:projects/users-blocked',
    component: () => <ClientProjectUsersBlocked/>
  },
  {
    path: '/clients/statistics',
    component: () => <ClientStatistics/>
  },
  {
    path: '/clients/:id',
    component: () => <ClientLicenseData/>
  },
  {
    path: '/clients/statistics/registered-last-month',
    component: () => <ClientStatistics/>
  },
  {
    path: '/clients/statistics/without-activity',
    component: () => <ClientStatistics/>
  },
  {
    path: '/clients/statistics/non-payment',
    component: () => <ClientStatistics/>
  },
  {
    path: '/clients',
    component: () => <ClientsContent/>
  },
  {
    path: '/control-account/list',
    component: () => <ControlAccountList/>
  },
  {
    path: '/control-account/annotate',
    component: () => <ControlAccountAnnotate/>
  },
  {
    path: '/control-account',
    component: () => <ControlAccountList/>
  },
  {
    path: '/service-projects/representives',
    component: () => <ServiceProjectsRepresentatives/>
  },
  {
    path: '/service-projects/benefit',
    component: () => <ServiceProjectBenefit/>
  },
  {
    path: '/service-projects',
    component: () => <ServiceProjectsRepresentatives/>
  },
  {
    path: '/database/actives',
    component: () => <DatabaseActives/>
  },
  {
    path: '/database/removes',
    component: () => <DatabaseRemoves/>
  },
  {
    path: '/database',
    component: () => <DatabaseContent/>
  },
  {
    path: '/red101obex/representatives',
    component: () => <Red101ObexRepresentatives/>
  },
  {
    path: '/red101obex/benefit',
    component: () => <Red101ObexBenefit/>
  },
  {
    path: '/red101obex',
    component: () => <Red101ObexRepresentatives/>
  },
  {
    path: '/fxExchange/configuration',
    component: () => <FxExchangeConfiguration/>
  },
  {
    path: '/fxExchange/benefit',
    component: () => <FxExchangeBenefit/>
  },
  {
    path: '/fxExchange',
    component: () => <FxExchangeBenefit/>
  },
]



const getProjectsFromState = (state) => {
  if (!state) return false;
  const projects = (((state.location || {}).state || {}).state || {}).projects || false;
  return projects;
}