import RequestHandler from './RequestHandler';
import { url, port } from '../configs/appConfig.json';
import Cookies from 'universal-cookie';

type ObexHeaders = {
  Authorization:string,
  'disable-cache':boolean
}

class ObexRequestHandler {
  private static url = `${url}:${port}`;
  private static cookies = new Cookies();

  private static getAuthToken() {
    const user = ObexRequestHandler.cookies.get('101Obex');
    if (!user) return '';
    const { token } = user;
    return `Bearer ${token}`;
  }
  private static getHeaders(disableCache: boolean = false): ObexHeaders { 
    const headers = { Authorization: '', 'disable-cache': disableCache };
    const token = this.getAuthToken();
    headers.Authorization = token;
    return headers;
  }

  public static async get(path: string, params?: object, disableCache: boolean = false): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders(disableCache);
      const result = await RequestHandler.get(url, params, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async post(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.post(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async put(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.put(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async patch(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.patch(url, payload, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async delete(path: string, params?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`;
      const headers = this.getHeaders();
      const result = await RequestHandler.delete(url, params, headers);
      return result;
    } catch (error) {
      console.error('error in obex request handler(delete)', error)
    }
  }

}

export default ObexRequestHandler;