import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Button } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes  } from '@fortawesome/free-solid-svg-icons';

const PluginsContent:FunctionComponent<{}> = () => {
  const history = useHistory();
  
  const [plugins, setPlugins] = useState([]);

  const getPlugins = async () => {
    try {
      const plugins = (await ObexRequestHandler.get('/plugins')).data || [];
      setPlugins(plugins);
    } catch (error) {
      console.error('ERROR FETCHING PLUGINS ', error);
    }
  }

  const handlePluginDetail = (event, plugin) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/plugins/${plugin.name}`);
  }

  useEffect(() => {
    getPlugins();
  }, []);

  return(
    <DashLayout sider={DashRouters} active="plugins">
       <Row>
        <Col className="mx-auto">
          <Row>
            <Col>
              <h1 className="mb-3">Plugins</h1>
            </Col>
          </Row>
          <Row className="mb-3 border-bottom">
            <Col className="">
              <span>Name</span>
            </Col>
            <Col className="">
              <span>Commissions</span>
            </Col>
            <Col className="text-center">
              <span>Active</span>
            </Col>
            <Col className="text-center">
              <span>Fess affiliate</span>
            </Col>
            <Col className="text-center">
              <span>Balance</span>
            </Col>
            <Col md="3"></Col>
          </Row>
          {plugins.map(plugin => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  <span>{plugin.name}</span>
                </Col>
                <Col className="text-center">
                  <span>{plugin.active}</span>
                </Col>
                <Col className="text-center">
                  <span>{plugin.commissions}</span>
                </Col>
                <Col className="text-center">
                  <span>{plugin.fees_affiliate}</span>
                </Col>
                <Col className="text-center">
                  <span>{plugin.balance}</span>
                </Col>
                <Col md="3" className="text-center">
                  <Row>
                    <Col md="8">
                      <Button onClick={(event) => handlePluginDetail(event, plugin)} type="submit" className="btn-block obex-btn btn-green">
                        edit
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn-block obex-btn btn-orange">
                        <FontAwesomeIcon icon={faTimes}/>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          })}
        </Col>
      </Row>
    </DashLayout>
  )
}

export default PluginsContent;