import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { useHistory, useParams } from 'react-router-dom';
import AddBalanceModalPlugin from '../Modals/addBalancePlugin';
import ButtonSubmit from '../basicComponents/buttonSubmit';

const PluginAdd:FunctionComponent<{}> = () => {
  
  const [defaultAffiliate, setDefaultAffiliate] = useState({});
  const [feesAffiliate, setFeesAffiliate] = useState({});
  const [projectsAffiliate, setprojectsAffiliate] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [projectSelected, setProjectSelected] = useState({});
  const [pluginName, setPluginName] = useState("");
  const [pluginMonthlyPrice, setPluginMonthlyPrice] = useState(Number());
  const [pluginAnnualPrice, setPluginAnnualPrice] = useState(Number());
  const [pluginPayment, setPluginPayment] = useState(Boolean());
  const [pluginDescription, setPluginDescription] = useState(String());
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const [projectName, setProjectName] = useState(String());
  const [representativeId, setRepresentativeId] = useState(Number());
  const [representativePvk, setRepresentativePvk] = useState(String());
  const [pluginId, setPluginId] = useState(Number());

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const createPlugin = async(e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      const payload = { 
        "description": pluginDescription,
        "price": pluginMonthlyPrice
      };
      const result = await ObexRequestHandler.put(`/plugins/add`, payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
    } catch (error) {
      console.error('ERROR WHILE PUT PLUGIN DETAIL ', error);
      setError(error.message || error);
    }
    setLoading(false);
  }

  const handleModalPlugin = (event, project) => {
    setPluginId(project.obex_plugin_id);
    setProjectName(project.custom_project_id);
    setRepresentativeId(project.representative_id);
    setRepresentativePvk(project.representative_pvk);
    setProjectSelected(project);
    setShowModal(!showModal);
  }

  const handleMonthlyPrice = (e) => {
    setPluginMonthlyPrice(e.target.value);
    setSubmitDisabled(false);
  }

  const handleAnnualPrice = (e) => {
    setPluginAnnualPrice(e.target.value);
    setSubmitDisabled(false);
  }

  const handleDescription = (e) => {
    setPluginDescription(e.target.value);
    setSubmitDisabled(false);
  }

  return(
    <DashLayout sider={DashRouters} active="admin">
       <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col >
              <h1 className="mb-3">Plugin Add</h1>
            </Col>
          </Row>
          <Row>
            <Col className="mx-auto">
              <Card className="product-detail-card mb-3">
                <Card.Body className="p-3">
                  <Row>
                    <Col className="mx-auto">
                      <Form className="obex-form" onSubmit={createPlugin}>
                        <Row>
                          <Col md="6">
                            <Form.Row className="mb-3">
                              <Form.Label column md="6" className="obex-form-label">
                                Plugin Name
                              </Form.Label>
                              <Col md="4">
                              <Form.Control
                                readOnly 
                                plaintext
                                value={pluginName}/>
                              </Col>
                            </Form.Row>
                          </Col>
                          <Col md="6">
                            <Form.Row className="mb-3">
                              <Form.Label column md="6" className="obex-form-label">
                                Type
                              </Form.Label>
                              <Col>
                                <Form.Control 
                                  readOnly 
                                  plaintext
                                  value={pluginPayment ? "Payment" : "Charge"}
                                />
                              </Col>
                            </Form.Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6"><Form.Row className="mb-3">
                            <Form.Label column md="6" className="obex-form-label">
                              Monthly price
                            </Form.Label>
                            <Col>
                              <Form.Control 
                              type="number" 
                              placeholder="Monthly price" 
                              value={pluginMonthlyPrice} 
                              onChange={handleMonthlyPrice}/>
                            </Col>
                          </Form.Row>
                          </Col>
                          <Col md="6">
                            <Form.Row className="mb-3">
                              <Form.Label column md="6" className="obex-form-label">
                                Annual price
                              </Form.Label>
                              <Col>
                                <Form.Control 
                                type="number" 
                                placeholder="Annual price" 
                                value={pluginAnnualPrice}
                                onChange={handleAnnualPrice}/>
                              </Col>
                            </Form.Row>
                          </Col>
                        </Row>
                        <Form.Row className="mb-3">
                          <Form.Label column md="3" className="obex-form-label">
                            Description
                          </Form.Label>
                          <Col>
                            <Form.Control 
                            type="text" 
                            as="textarea" 
                            rows={4} 
                            placeholder="Description" 
                            value={pluginDescription} 
                            onChange={handleDescription}/>
                          </Col>
                        </Form.Row>
                        <ButtonSubmit 
                          align="right"
                          loading={loading}
                          success={success}
                          error={error}
                          handleSubmit={createPlugin}
                          submitDisabled={submitDisabled}
                          textButton={'Update'}/>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {showModal && 
        <AddBalanceModalPlugin 
          projectId={projectName}
          representativeId={representativeId}
          representativePvk={representativePvk}
          pluginId={pluginId}
          show={showModal} 
          handleClose={() => setShowModal(false)}
        />
      }
    </DashLayout>
  )
}

export default PluginAdd;