import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner, InputGroup, FormControl } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare  } from '@fortawesome/free-solid-svg-icons';
import ButtonSubmit from '../basicComponents/buttonSubmit';


type BalancePluginProps = {
  projectId: string,
  representativeId: number,
  representativePvk: string,
  pluginId: number
  show: boolean,
  handleClose: () => void
}
const AddBalanceModalPlugin:FunctionComponent<BalancePluginProps> = (props) => {

  const {projectId,representativeId, representativePvk, pluginId, show, handleClose} = props;

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const [amount, setAmount] = useState(Number());

  const handleAmount = ((e) => {
    setAmount(e.target.value);
    setSubmitDisabled(false);
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    try {
      setError('');// Limpiamos los posibles errores
      const payload = {
        'amount': amount,
        'rep_id': representativeId,
        'rep_pk': representativePvk,
        'proj_plug_id': pluginId
      }
      const result = await ObexRequestHandler.post(`/recharge_plugin/${projectId}`, payload);
      console.log("RESULTADO DE RECHARGE PLUGIN", result);
      const { success, data, message } = result;
      setSuccess(success);
      setError(message);
    } catch (error) {
      console.error('ERROR RECHARGE PLUGINS ', error);
    }
    setLoading(false);
  };

  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={show} 
      onHide={handleClose}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Col md="12" className="addons-title">
            <span>Add balance to representative</span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-2">
          <Col md="7" className="mx-auto">
            <Form className="obex-form" onSubmit={handleSubmit}>
              <Form.Row className="mb-3">
                <Form.Label column sm="6" className="obex-form-label">
                  Balance
                </Form.Label>
                <Col md="6">
                  <Form.Control required type="number" placeholder="0.00" onChange={handleAmount}/>
                </Col>
              </Form.Row>
              <ButtonSubmit
                submitDisabled={submitDisabled}
                success={success}
                error={error}
                loading={loading}
                textButton="Add balance"
                handleSubmit={handleSubmit}
              />
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default AddBalanceModalPlugin;