import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const FooterMenu:FunctionComponent<{}> = () => {
  return (
    <Nav id="footer-dashboard" className="justify-content-md-center ">
      <Nav.Item>
        <Nav.Link href="#">Contacto</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="#">Privacidad</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="#">Acuerdos legales</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="#">Mundo</Nav.Link>
      </Nav.Item>
    </Nav>
  );
}


export default FooterMenu;