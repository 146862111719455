import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


type AddonsProps = {}


const AddonsConfiguration:FunctionComponent<AddonsProps> = (props) => {

  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addons, setAddons] = useState(Array());

  const getAddons = async () => {
    setLoading(true);
    try {
      const { success, data, message } = await ObexRequestsHandler.get('/addons');
      if (!success) throw { message };   
      setAddons(data);
      console.log(data);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  useEffect(()  => {
    getAddons();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="addons">
      <Row>
        <Col md="11" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Addons Config</h1>
            </Col>
          </Row>
          <Row className="mb-3 border-bottom">
            <Col>Title</Col>
            <Col>Monthly Price</Col>
            <Col>Annual Price</Col>
            <Col md="3"></Col>
          </Row>
          {addons.map(addon => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  <span>{addon.title}</span>
                </Col>
                <Col className="">
                  <span>{addon.monthly_price}</span>
                </Col>
                <Col className="">
                  <span>{addon.annual_price}</span>
                </Col>
                <Col md="3" className="text-center">
                  <Row>
                    <Col md="8">
                      <Button /* onClick={(event) => handlePluginDetail(event, plugin)} */ type="submit" className="btn-block obex-btn btn-green">
                        edit
                      </Button>
                    </Col>
                    <Col>
                      <Button type="submit" className="btn-block obex-btn btn-orange">
                        <FontAwesomeIcon icon={faTimes}/>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          )}
        </Col>
      </Row>
    </DashLayout>
  )
}

export default AddonsConfiguration;