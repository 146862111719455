import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Accordion, Nav, Row, Col, Image, Button } from 'react-bootstrap';
import ficha_user from '../../assets/img/pastilla2.png';

const SupportBanner:FunctionComponent<{}> = (props) => {

  return (
    <div className="box-support mt-auto text-center px-4">
      <Col md="5" className="my-3 mx-auto">
        <Image src={ficha_user}  fluid/>
      </Col>
      <Col md="12" className="title-box mb-3">
        <span>Contrata Soporte, obten ayuda siempre.</span>
      </Col>
      <Col md="12" className="text-box mb-3">
        <span>Aquí habrá un texto descriptivo con las caracteristicas de soporte. Sed ut perspiciatis</span>
      </Col>
      <Col md="12" className="mb-3">
        <Button className="obex-btn btn-green">Contratar</Button>
      </Col>
    </div>
  )
};

export default SupportBanner;