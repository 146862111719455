import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { useHistory } from 'react-router-dom';

type ClientsProps = {}



const ClientsContent:FunctionComponent<ClientsProps> = (props) => {

  const history = useHistory();
  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(Array());

  const getClients = async () => {
    setLoading(true);
    try {
      const { success, data, message } = await ObexRequestsHandler.get('/users');
      if (!success) throw { message };
      const clientsList = data.sort((a, b) => a.id > b.id ? 1 : -1).filter((client) => client.admin == false);
      setClients(clientsList);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  const handleClientDetail = (event, client) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`/clients/${client.id}`);
  }

  useEffect(()  => {
    getClients();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="clients">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Clients List</h1>
            </Col>
          </Row>
          <Row className="mb-3 border-bottom">
            <Col>Username</Col>
            <Col>Email</Col>
            <Col md="2"></Col>
          </Row>
          {clients.map(client => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  <span>{client.username}</span>
                </Col>
                <Col className="">
                  <span>{client.email}</span>
                </Col>
                <Col md="2" className="">
                <Button onClick={(event) => handleClientDetail(event, client)} type="submit" className="btn-block obex-btn btn-green">
                    edit
                  </Button>
                </Col>
              </Row>
            )}
          )}
        </Col>
      </Row>
    </DashLayout>
  )
}

export default ClientsContent;