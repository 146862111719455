import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Card, Button, Form, FormControl, Spinner, InputGroup  } from 'react-bootstrap';
import ObexRequestsHandler from '../../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../../basicComponents/buttonSubmit';


type CronsProps = {}



const CronsContent:FunctionComponent<CronsProps> = (props) => {

  //const { } = props;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState(Array());

  const handleGetTasks = async () => {
    setLoading(true);
    try {
      const { success, data, message } = await ObexRequestsHandler.get('/tasks');
      if (!success) throw { message };   
      setTasks(data);
      console.log(data);
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    };
  };

  useEffect(()  => {
    handleGetTasks();
  },[]);


  return(
    <DashLayout sider={DashRouters} active="crons">
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
            <h1 className="mb-3">Crons Active</h1>
            </Col>
          </Row>
          <Row className="mb-3 border-bottom">
            <Col>Name</Col>
            <Col>Cron Human Expression</Col>
            <Col>Cron Expression</Col>
          </Row>
          {tasks.map(task => { 
            return (
              <Row className="mb-3">
                <Col className="">
                  <span>{task.name}</span>
                </Col>
                <Col className="">
                  <span>{task.humanCronExpression}</span>
                </Col>
                <Col className="">
                  <span>{task.cronExpression}</span>
                </Col>
              </Row>
            )}
          )}
        </Col>
      </Row>
    </DashLayout>
  )
}


const CronsActived:FunctionComponent<{}> = () => {

  const [ projectsNumbers, setProjectsNumbers ] = useState('');
  const [ monthRateLimit, setMonthRateLimit ] = useState('');
  const [ nameSubs, setNameSubs ] = useState('');
  const [ project, setProject] = useState(false);
  const [ staging, setStaging ] = useState(false);
  const [ cipher, setCipher ] = useState(false);
  const [ mail, setMail ] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ submitDisabled, setSubmitDisabled ] = useState(true);
  const [ createdSuccess, setCreatedSuccess ] = useState(false);
  const [ userInfo, setUserInfo ] = useState({});
  
  const handleProjectsNumbers = (e) => {setProjectsNumbers(e.target.value)}
  const handleMonthRateLimit = (e) => {setMonthRateLimit(e.target.value)}
  const handleNameSubs = (e) => {setNameSubs(e.target.value)}
  const handleMail = (e) => {setMail(e.target.value)}
  const handleProject = (e) => {setProject(!project)};
  const handleStaging = (e) => {setStaging(!staging)};
  const handleCipher = (e) => {setCipher(!cipher)};

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setError('');
    setLoading(true);
    const subscription = await handleSubs();
    console.log('subscription ', subscription)
    const { id } = subscription;
    setUserInfo(await handleCustomUser(id));
    setCreatedSuccess(true);
    setLoading(false);
  };

  const handleSubs = async () => {
    try {
      const payload = {
        projects_numbers: projectsNumbers,
        month_rate_limit: monthRateLimit,
        name: nameSubs
      }
      const { success, data, message } = await ObexRequestsHandler.post('/subscriptions', payload);
      if (!success) throw { message };   
      return data;
    } catch (error) {
      setError(error.message || error)
      setLoading(false);
    }

  }

  const handleCustomUser = async (subscription) => {
    try {
      const payload = {
        email: mail,
        subscription: subscription,
        project: project,
        staging: staging,
        cipher: cipher
      }
      const { success, data, message } = await ObexRequestsHandler.post('/create_custom_user', payload);
      if (!success) throw { message };   
      return data;
    } catch (error) {
      setError(error.message || error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (projectsNumbers && monthRateLimit && nameSubs && mail){
      setSubmitDisabled(false);
    }else{
      setSubmitDisabled(true);
    }
  },[ projectsNumbers, monthRateLimit, nameSubs, mail ])

  return (
    <DashLayout sider={DashRouters} active="admin">
      <CronsContent />
    </DashLayout>
  );
}

export default CronsContent;